// analytics.ts
import { datadogRum } from '@datadog/browser-rum';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

// analyticsEvents.ts
export enum AnalyticsEvent {
  // Pickles only
  CLICK_CONTINUE_APPLICATION = 'clickOnContinueApplication',

  // All Forms
  FORM_SUBMIT_ERROR = 'formSubmitError',

  EXPRESS_FORM_PAGE_LOADED = 'expressFormPageLoaded',
  EXPRESS_FORM_SUBMITTED = 'expressFormSubmitted',
  LEAD_SUBMIT_SUCCESS = 'leadSubmitSuccess',
  LEAD_SUBMIT_FAILED_MOBILE_EXISTS = 'leadSubmitFailedMobileNumberExists',
  LEAD_SUBMIT_FAILED_EMAIL_EXISTS = 'leadSubmitFailedEmailExists',
  THANK_YOU_PAGE_LOADED = 'thankYouPageLoaded',
  
  FOCUS_FIRST_NAME = 'focusOnFirstName',
  VALIDATION_ERROR_FIRST_NAME = 'validationErrorOnFirstName',

  FOCUS_LAST_NAME = 'focusOnLastName',
  VALIDATION_ERROR_LAST_NAME = 'validationErrorOnLastName',

  FOCUS_EMAIL = 'focusOnEmail',
  VALIDATION_ERROR_EMAIL = 'validationErrorOnEmail',

  FOCUS_MOBILE = 'focusOnMobile',
  VALIDATION_ERROR_MOBILE = 'validationErrorOnMobile',

  FOCUS_STATE = 'focusOnState',
  VALIDATION_ERROR_STATE = 'validationErrorOnState',

  FOCUS_LOAN_AMOUNT = 'focusOnLoanAmount',
  VALIDATION_ERROR_LOAN_AMOUNT = 'validationErrorOnLoanAmount',

  FOCUS_BUSINESS_NAME = 'focusOnBusinessName',
  VALIDATION_ERROR_BUSINESS_NAME = 'validationErrorOnBusinessName',

  FOCUS_BUSINESS_STRUCTURE = 'focusOnBusinessStructure',
  VALIDATION_ERROR_BUSINESS_STRUCTURE = 'validationErrorOnBusinessStructure',

  SELECT_CARCLARITY_PRIVACY_POLICY = 'selectCarClarityPrivacyPolicy',
  UNSELECT_CARCLARITY_PRIVACY_POLICY = 'unselectCarClarityPrivacyPolicy',
  VALIDATION_ERROR_CARCLARITY_PRIVACY_POLICY = 'validationErrorOnCarClarityPrivacyPolicy',

  SELECT_PARTNERS_PRIVACY_POLICY = 'selectPartnerPrivacyPolicy',
  UNSELECT_PARTNERS_PRIVACY_POLICY = 'unselectPartnerPrivacyPolicy',
  VALIDATION_ERROR_PARTNERS_PRIVACY_POLICY = 'validationErrorOnPartnerPrivacyPolicy',

  CLICK_PARTNERS_PRIVACY_POLICY_LINK = 'clickOnPartnersPrivacyPolicyLink',

  CLICK_CARCLARITY_PRIVACY_POLICY_LINK = 'clickOnCarClarityPrivacyPolicyLink',
  CLICK_CARCLARITY_CREDIT_GUIDE_LINK = 'clickOnCarClarityCreditGuideLink',
  CLICK_CARCLARITY_PRIVACY_CONSENT = 'clickOnCarClarityPrivacyConsent',
  CLICK_CARCLARITY_ELECTRONIC_DOCUMENT_DELIVERY_CONSENT_LINK = 'clickOnCarClarityElectronicDocumentDeliveryConsentLink',
}

interface TrackAnalyticsOptions {
  event: AnalyticsEvent;                      
  payload?: Record<string, unknown>;
}


export function trackAnalytics({
  event,
  payload = {},
}: TrackAnalyticsOptions) {

const datadogEventMap: Record<AnalyticsEvent, string> = {
  [AnalyticsEvent.EXPRESS_FORM_PAGE_LOADED]: 'EF-PageLoaded',
  [AnalyticsEvent.EXPRESS_FORM_SUBMITTED]: 'EF-Submitted',
  [AnalyticsEvent.LEAD_SUBMIT_SUCCESS]: 'EF-Submit-Success',
  [AnalyticsEvent.CLICK_CONTINUE_APPLICATION]: 'EF-Click-ContinueApplication',
  [AnalyticsEvent.FORM_SUBMIT_ERROR]: 'formSubmitError',
  [AnalyticsEvent.LEAD_SUBMIT_FAILED_MOBILE_EXISTS]: '',
  [AnalyticsEvent.LEAD_SUBMIT_FAILED_EMAIL_EXISTS]: 'EF-Submit-Failed-EmailExists',
  [AnalyticsEvent.THANK_YOU_PAGE_LOADED]: 'EF-ThankYou-Loaded',
  [AnalyticsEvent.FOCUS_FIRST_NAME]: 'EF-Focus-FirstName',
  [AnalyticsEvent.VALIDATION_ERROR_FIRST_NAME]: 'EF-Validation-FirstName',
  [AnalyticsEvent.FOCUS_LAST_NAME]: 'EF-Focus-LastName',
  [AnalyticsEvent.VALIDATION_ERROR_LAST_NAME]: 'EF-Validation-LastName',
  [AnalyticsEvent.FOCUS_EMAIL]: 'EF-Focus-Email',
  [AnalyticsEvent.VALIDATION_ERROR_EMAIL]: 'EF-Validation-Email',
  [AnalyticsEvent.FOCUS_MOBILE]: 'EF-Focus-Mobile',
  [AnalyticsEvent.VALIDATION_ERROR_MOBILE]: 'EF-Validation-Mobile',
  [AnalyticsEvent.FOCUS_STATE]: 'EF-Focus-State',
  [AnalyticsEvent.VALIDATION_ERROR_STATE]: 'EF-Validation-State',
  [AnalyticsEvent.FOCUS_LOAN_AMOUNT]: 'EF-Focus-LoanAmount',
  [AnalyticsEvent.VALIDATION_ERROR_LOAN_AMOUNT]: 'EF-Validation-LoanAmount',
  [AnalyticsEvent.FOCUS_BUSINESS_NAME]: 'EF-Focus-BusinessName',
  [AnalyticsEvent.VALIDATION_ERROR_BUSINESS_NAME]: 'EF-Validation-BusinessName',
  [AnalyticsEvent.FOCUS_BUSINESS_STRUCTURE]: 'EF-Focus-BusinessStructure',
  [AnalyticsEvent.VALIDATION_ERROR_BUSINESS_STRUCTURE]: 'EF-Validation-BusinessStructure',
  [AnalyticsEvent.SELECT_CARCLARITY_PRIVACY_POLICY]: 'EF-Select-PrivacyPolicy',
  [AnalyticsEvent.UNSELECT_CARCLARITY_PRIVACY_POLICY]: 'EF-Unselect-PrivacyPolicy',
  [AnalyticsEvent.VALIDATION_ERROR_CARCLARITY_PRIVACY_POLICY]: 'EF-Validation-PrivacyPolicy',
  [AnalyticsEvent.SELECT_PARTNERS_PRIVACY_POLICY]: 'EFP-Select-PrivacyPolicy',
  [AnalyticsEvent.UNSELECT_PARTNERS_PRIVACY_POLICY]: 'EFP-Unselect-PrivacyPolicy',
  [AnalyticsEvent.VALIDATION_ERROR_PARTNERS_PRIVACY_POLICY]: 'EFP-Validation-PrivacyPolicy',
  [AnalyticsEvent.CLICK_PARTNERS_PRIVACY_POLICY_LINK]: 'EFP-Click-PrivacyPolicyLink',
  [AnalyticsEvent.CLICK_CARCLARITY_PRIVACY_POLICY_LINK]: 'EF-Click-PrivacyPolicyLink',
  [AnalyticsEvent.CLICK_CARCLARITY_CREDIT_GUIDE_LINK]: 'EF-Click-CreditGuideLink',
  [AnalyticsEvent.CLICK_CARCLARITY_PRIVACY_CONSENT]: 'EF-Click-PrivacyConsentLink',
  [AnalyticsEvent.CLICK_CARCLARITY_ELECTRONIC_DOCUMENT_DELIVERY_CONSENT_LINK]: 'EF-Click-DeliveryConsentLink'
};
  if (window.dataLayer && Array.isArray(window.dataLayer)) {
    window.dataLayer.push({ event, ...payload });
  }

  const datadogName = datadogEventMap[event] || event;

  datadogRum.addAction(datadogName, payload);
}
